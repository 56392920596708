import React, { useState, useEffect, PropTypes } from "react"
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Modules from '../components/modules'
import useDeviceMedia from "../components/utils/useDeviceMedia";
import { LATEST_NEWS_PAGE_URL } from "../components/common/site/constants";
import NewsDetail  from "../components/Home/News/NewsDetail";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/default-template.scss";

const BlogDetailsTemplate = (props) => {
    let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null

    const GQLPage = props.data.glstrapi?.article;
    const Site = props.data.glstrapi?.siteConfig;

    let custom_css = ''
    if (GQLPage?.Custom_CSS_Classname) {
      custom_css = GQLPage.Custom_CSS_Classname
    }
    const layoutClass = GQLPage?.Layout ? `page-layout-${GQLPage?.Layout.toLowerCase().replace(/\s+/g, '-')}` : '';

    return (
      <div className={`blog-details-template page-news-insights-wrap default-template ${custom_css} ${layoutClass}`}>
        <SEO title={GQLPage?.Meta_Title} description={GQLPage?.Meta_Description} location={props.location} />
      <div className={`static-page`}>
        <Header homeArticleId={props.pageContext} location={props.location} showMenu={""} handlerClick={""} fixed={true}  TransparentHeader= '' />  
                <div className={language === 'Russian' ? "russian-page-modules page-modules" : "page-modules"}>               
                  <Breadcrumb 
                    data={{
                      Label: 'Blogs',
                      Main_Parent: {
                        Label: 'News & Insights', 
                        id: "609cb72bf0c22758e21cd02e", 
                        URL: 'property-news'
                      }, 
                      Sub_Parent: null
                    }} 
                    page={props?.pageContext?.post_name || ""} 
                    tag="details" 
                    category={props?.pageContext?.category_url || ""} 
                    name={props?.pageContext?.post_url || ""} 
                  /> 

                  <NewsDetail 
                    detailpage={props?.pageContext?.post_url || ""} 
                    alias={LATEST_NEWS_PAGE_URL.alias} 
                    category={props?.pageContext?.category_url || ""} 
                    site={Site} 
                    timings={Site.Available_Timings} 
                    location={props.location} 
                  />
               </div>
               <Footer popularSearch={GQLPage.Popular_Search} />
                {GQLPage.Show_CTA_Sticky_Mobile && 
                <StickyFooter cta_1={GQLPage.Header_CTA_1_Label}
                            cta_2={GQLPage.Header_CTA_2_Label}
                            cta_1_link={GQLPage.Header_CTA_1_Link}
                            cta_2_link={GQLPage.Header_CTA_2_Link}
                            search={GQLPage.Show_Search_Type} />
                }
      </div>
    </div>
  )
}

export default BlogDetailsTemplate

export const pageQuery = graphql`
  query BlogDetailsQuery ($article_id: ID!) {
      glstrapi {
        article(id: $article_id, publicationState: LIVE) {
          _id
          Pagename
          Meta_Title
          Meta_Description
          Layout
          Header_CTA_2_Label
          Header_CTA_1_Label
          Show_Search_Type
          Show_CTA_Sticky_Mobile
          Custom_CSS_Classname
          Popular_Search
          Header_CTA_1_Link {
            id
          }
          Header_CTA_2_Link {
            id
          }
          all_menus {
            Label
            Main_Parent {
              Label
              id
              URL
            }
            Sub_Parent {
              Label
              URL
              id
            }
          } 
        }      
        siteConfig {
          Address
          Email
          Phone
          Available_Timings
        }
      }
  }
`